<template>
  <div class="carBrand">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this car brand:
          <b>{{ delCarBrand.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelCarBrand()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add car dialog box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Car Brand
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="addCarBrand.name" label="Name"></v-text-field>
          <v-text-field
            v-model="addCarBrand.description"
            label="Description"
          ></v-text-field>
          <v-file-input
            @change="chooseImage"
            v-model="imgFile"
            clearable
            label="Logo"
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
          <v-switch
            v-model="addCarBrand.recommendFlag"
            class="ma-2"
            label="Featured"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddCarBrand()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Car Brand
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editCarBrand.name" label="Name"></v-text-field>
          <v-text-field
            v-model="editCarBrand.description"
            label="Description"
          ></v-text-field>
          <v-file-input
            label="Logo"
            @change="chooseImage"
            v-model="editImgFile"
            clearable
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
          <v-switch
            v-model="editCarBrand.recommendFlag"
            class="ma-2"
            label="Featured"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarBrand()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search box -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="nameSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="dialog = !dialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>

    <!-- pagination table -->
    <v-data-table
      :headers="headers"
      :items="carBrand"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.description }}</td>
          <td>
            <img
              style="height:50px; padding-top: 6px"
              v-if="row.item.logoPath != null"
              v-bind:src="row.item.logoPath"
            />
          </td>
          <td>{{ row.item.recommendFlag }}</td>
          <td>{{ row.item.status }}</td>
          <td>
            <v-switch
              v-model="row.item.adHideFlag"
              @change="onToggleHide(row.item)"
            ></v-switch>
          </td>
          <td>
            <v-switch
              v-model="row.item.piHideFlag"
              @change="onToggleHide(row.item)"
            ></v-switch>
          </td>
          <td>
            <v-switch
              v-model="row.item.hideFlag"
              @change="onToggleHide(row.item)"
            ></v-switch>
          </td>

          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import uploadImage from "../../../services/image-uploader";

import postAxios from "../../../services/axios-post";
import putAxios from "../../../services/axios-put";
import getAxios from "../../../services/axios-get";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      addCarBrand: {
        name: null,
        description: null,
        logoPath: null,
        recommendFlag: 0,
      },
      editCarBrand: {
        id: "",
        name: "",
        description: "",
        logoPath: "",
        recommendFlag: 0,
      },
      delCarBrand: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Car Brand", value: "name" },
        { text: "Description", value: "description" },
        { text: "Logo", value: "logoPath" },
        { text: "Featured", value: "recommendFlag" },
        { text: "Status", value: "status" },
        { text: "AD Hide", value: "adHideFlag" },
        { text: "PI Hide", value: "piHideFlag" },
        { text: "Used Hide", value: "hideFlag" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      carBrand: [],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchCarBrand();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCarBrand();
    },
    delButtonClick(item) {
      this.delCarBrand.id = item.id;
      this.delCarBrand.name = item.name;
      this.deleteDialog = true;
    },
    editButtonClick(item) {
      this.editCarBrand.id = item.id;
      this.editCarBrand.code = item.code;
      this.editCarBrand.name = item.name;
      this.editCarBrand.description = item.description;
      this.editCarBrand.logoPath = item.logoPath;
      this.editCarBrand.recommendFlag = item.recommendFlag;
      this.editDialog = true;
    },
    doDelCarBrand() {
      const self = this;
      const param = {
        carBrandId: self.delCarBrand.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/delCarBrand`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchCarBrand();
            self.showAlert("success", "Car Brand Deleted Successfully!");
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    async doEditCarBrand() {
      const self = this;
      let attachmentPath = "";

      let featured = 0;
      if (self.editCarBrand.recommendFlag) {
        featured = 1;
      }

      try {
        if (self.editImgFile != null) {
          const imgRes = await uploadImage(self.editImgFile);
          if (imgRes.data.httpStatus !== "OK") {
            console.log(imgRes.data.desc);
            return;
          }
          attachmentPath = imgRes.data.data;
        }
        const params = {
          id: self.editCarBrand.id,
          code: self.editCarBrand.name,
          name: self.editCarBrand.name,
          description: self.editCarBrand.description,
          logoPath: attachmentPath ? attachmentPath : null,
          recommendFlag: featured,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/updCarBrand`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editDialog = false;
              self.fetchCarBrand();
              self.showAlert("success", "Car Brand Edited Successfully!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },

    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          id: item.id,
          hideFlag: item.hideFlag ? 1 : 0,
          adHideFlag: item.adHideFlag ? 1 : 0,
          piHideFlag: item.piHideFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/updCarBrand`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editDialog = false;
            self.fetchCarBrand();
            self.showAlert("success", "Hide updated Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
      }
    },
    async doAddCarBrand() {
      const self = this;
      try {
        var attachmentPath = null;
        if (self.imgFile) {
          const imgRes = await uploadImage(self.imgFile);
          attachmentPath = imgRes.data.data;
        }
        let featured = 0;
        if (self.addCarBrand.recommendFlag) {
          featured = 1;
        }
        const params = {
          name: self.addCarBrand.name,
          description: self.addCarBrand.description,
          logoPath: attachmentPath ? attachmentPath : null,
          recommendFlag: featured,
        };
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/addCarBrand`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.dialog = false;
              self.fetchCarBrand();
              self.showAlert("success", "New Car Type Added!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    chooseImage() {
      this.addCarBrand.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchType() {
      this.fetchCarBrand();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchCarBrand() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.carBrand = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
